@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

// Here you can add other styles
.body {
  background: #020012;
  // background: linear-gradient(to bottom, #030016, #000);
  margin: 0;
  /* Optional: removes default margin */
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.footer-bg {
  background: url("../../public/background-footer.png");
  width: 100%;
  top: 0px;
  background-color: #020012;
  background-size: cover !important;
  background-repeat: no-repeat;
}

.before-after-bg {
  background: url("../../public/before-after.svg");
  width: 100%;
  top: 0px;
  background-color: #020012;
  background-size: contain !important;
  background-repeat: no-repeat;
  height: 100vh;
  background-position: center center;
}

.benefits-bg {
  background: url("../../public/benefits-bg.png");
  width: 100%;
  top: 0px;
  background-color: #020012;
  background-size: contain !important;
  background-repeat: no-repeat;
  height: 140vh;
  background-position: center center;
}

.invest-form-bg {
  background: url("../../public/invest-bg.png");
  width: 100%;
  top: 0px;
  background-color: #000;
  background-size: cover !important;
  background-repeat: no-repeat;
  height: 80vh;
}

h2 {
  font-family: 'Montserrat';
  font-size: 42px;
  color: #009CF3;
  font-weight: 700;
}

h6 {
  font-family: "Montserrat";
  color: white;
  font-size: x-large;
  font-weight: 700;
  margin-bottom: 20px;
}

small {
  font-family: "Montserrat";
  color: white;
  font-weight: 400;
}

p {
  font-family: "Montserrat";
  color: white;
  font-weight: 400;
}

.main-container {
  padding: 0px;
}

.sections-container {
  padding: 0;
}

.app-header {
  background: #030016;
}

.header-title {
  font-size: small;
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
}

.header-menu-item {
  margin: auto 30px !important;
}

.header-help-center {
  display: flex !important;
  align-items: center !important;
}

.help-link {
  cursor: pointer;
}

.help-link:hover {
  color: #009CF3 !important;
}

.language-selector {
  color: white;
  background: none;
  font-size: small;
  box-shadow: none !important;
}

.landing-title {
  font-size: 65px;
  color: white;
  font-weight: 700;
}

.landing-community-title {
  font-size: 45px;
  color: white;
  font-weight: 700;
}

.landing-subtitle {
  font-family: 'Montserrat';
  font-size: 25px;
  color: white;
  font-weight: 400;
  word-wrap: break-word;
}

.primary-btn {
  font-family: "Montserrat";
  font-weight: 700;
  padding: 10px 40px;
  border-radius: 50px;
  background: #009CF3;
  color: #010440;
}

.primary-btn-fixed {
  width: 320px;
}

.broken-arrow {
  cursor: pointer;
  rotate: 360deg;
  transition: transform 0.3s ease;
}

.broken-arrow:hover {
  transform: scale(1.2);
}

.broken-arrow-focus {
  rotate: 270deg;
}

.btn-div {
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;
  width: 100%;
}

.btn-div2 {
  margin-top: 50px;

  text-align: center;
  width: 100%;
}

.btn-div3 {
  margin-top: 50px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.sections-div {
  margin-bottom: 50px;
}

.strategies-row {
  --cui-gutter-x: none;
}

.card {
  border-radius: 1rem;
}

.strategies-card-bg-img {
  background: url("./../assets/images/ai.png");
  width: 100%;
  height: 260px;
  background-color: #f1f4fd;
  background-size: cover !important;
  background-repeat: no-repeat;
}

.add-ons-card-bg-img-2 {
  background: url("./../assets/images/ai-2.png");
  width: 100%;
  height: 150px;
  background-color: #f1f4fd;
  background-size: cover !important;
  background-repeat: no-repeat;
}

.add-ons-card-bg-img-3 {
  background: url("./../assets/images/ai-3.png");
  width: 100%;
  height: 150px;
  background-color: #f1f4fd;
  background-size: cover !important;
  background-repeat: no-repeat;
}

.investors-card-bg-img {
  background: url("./../assets/images/avatars/investor1.png");
  width: 100%;
  height: 158px;
  background-color: transparent;
  background-size: cover !important;
  background-repeat: no-repeat;
  border-radius: 0%;
}

.card-img {
  border-radius: 0.75rem;
}

.cards-hr {
  border: 2px solid #009cf3;
  margin: 0;
  opacity: 1;
}

.card-footer {
  border-radius: 1rem;
}

.supporters-avatars {
  width: -webkit-fill-available !important;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 170px;
}

.supporters-avatars:hover {
  box-shadow: -1px 0px 20px 2px rgba(0, 156, 243, 0.5);
}

.affiliated-avatars {
  width: -webkit-fill-available !important;
}



.landing-card-footer {
  background-color: rgba(5, 12, 74, 0.8);
}

.landing-card-title {
  font-weight: 700;
  margin-bottom: 0;
}

a.nav-link {
  color: white !important;
  font-family: 'Montserrat';
  font-weight: 700;
}

a.nav-link:hover {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #04a7ff !important;
}

a.nav-link.active {
  font-family: 'Montserrat';
  font-weight: 700;
  color: #009CF3 !important;
}

.btn.dropdown-toggle:hover {
  background: transparent;
  color: #009CF3;
}

.show>.btn.dropdown-toggle {
  color: #009CF3;
  background-color: transparent;
  border-color: transparent;
}

.poweredBy-container {
  background: linear-gradient(to bottom, #020012, #06050e)
}

.poweredBy-div {
  padding: 20px 0 60px 0;
  box-shadow: inset 0 5px 5px -5px #477C8F, inset 0 -5px 5px -5px #477C8F;
}

.footer-icons {
  margin: auto 8px auto 0;
  color: white;
}

.footer-icons:hover {
  color: #04a7ff !important;
  cursor: pointer;
}

// FORM
.custom-form {
  font-size: small;
  background: transparent;
  color: white;

  .col-md-12 {
    margin-bottom: 14px;
  }

  input {
    font-size: small;
    background: transparent;
    border: 2px solid #009cf3;
    color: white;
  }

  textarea {
    font-size: small;
    background: transparent;
    border: 2px solid #009cf3;
    color: white;
  }

  select {
    font-size: small;
    background: transparent;
    border: 2px solid #009cf3;
    color: white;
  }

  .required {
    color: rgb(214, 1, 1);
  }

  .form-select {
    background-image: url("./../assets/arrow.svg") !important;
    background-repeat: no-repeat;
    background-color: #000015 !important;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
  }

  .form-check {
    margin: 10px 0 !important;
    border: 2px solid #009cf3;
    width: 100%;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .form-check-input {
    margin-left: -0.5em !important;
  }

  .form-check-label {
    margin-left: 0.5em !important;
  }
}

.custom-form-2 {
  font-size: small;
  background: transparent;
  color: white;
  border: none !important;

  .required {
    color: rgb(214, 1, 1);
  }


}

#custom-row {
  margin-bottom: 14px !important;
}

#form-image {
  height: auto;
  width: 60%;
  border: 3px solid #009CF3;
  border-radius: 50%;
}

.MuiButtonBase-root {
  color: white !important;
}

.MuiTypography-root {
  color: white !important;
}

.css-1vooibu-MuiSvgIcon-root {
  border: 2px solid #009CF3;
  border-radius: 50%;
}

.mui-time-picker-title {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 2.66;
  letter-spacing: 0.08333em;
  text-transform: uppercase;
}

.unselected-timeslot {
  background: transparent !important;
  color: #009CF3 !important;
}

.selected-timeslot {
  background: #009cf3 !important;
  color: white !important;
}

.timeslot-btn:hover {
  color: white !important;

}

hr {
  border: 1px solid #009cf3;
  height: 1px;
  opacity: 1;
}

.MuiPickersDay-root:hover {
  border: 2px solid #009CF3 !important;
}

.css-1vooibu-MuiSvgIcon-root:hover {
  background: #009CF3;

}

.widget-container {
  color: white;
  font-size: 60px;
  font-weight: 700;
  padding: 5px 20px 16px;
  background-color: #00000080;
  border-radius: 5px;
  box-shadow: 0 2px 5px #000015;
  justify-content: center;
}

.widget-container-p {
  margin: 0;
  text-align: center;
  font-weight: 700;
  font-size: 4rem;
}

.widget-container-p-footer {
  margin: 0;
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
  color: #000;
}

.trash-icon:hover {
  background: transparent;
  border: 1px solid #e55353;
}

.view-icon:hover {
  background: transparent;
  border: 1px solid #009CF3;
}

.view-icon:focus .trash-icon:focus {
  background: transparent !important;
  background-color: transparent !important;
  outline: none !important;
  border: 1px solid #009CF3;
}

.info-tooltip {
  text-align: justify;
}

.MuiPickersLayout-actionBar {
  display: none;
}

.center-div {
  position: fixed;
  top: 60%;
}

.join-card {
  background-color: #010440;
  color: white;
  text-align: center;
  padding: 20px;
  margin: 20px;
  cursor: pointer;
}

.join-card:hover {
  box-shadow: -1px 0px 20px 2px rgba(0, 156, 243, 0.5);
}

.nav-tabs {
  cursor: pointer;
}

.accordion-button {
  background: transparent;
  border: 2px solid #009CF3;
  border-radius: 0.25rem;
  color: #fff;
  font-weight: 600;
}

.accordion-button::after {
  background-image: url("./../assets/arrow.svg") !important;
}

.accordion-button:not(.collapsed) {
  background-color: transparent;
}

.accordion-body {
  color: #fff;
}

.css-knqc4i-MuiDialogActions-root {
  display: none;
}


.ghost-btn {
  background: transparent;
  color: white;
  border: none;
}

.ghost-btn:hover {
  background: transparent;
  color: #009CF3;
}

#invest-arr-icon:hover {
  color: #009CF3;
  transform: scale(1.2);
}

#invest-arr-icon {
  transition: transform 0.3s ease;
}

.sax-icon {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.sax-icon:hover {
  transform: scale(1.2);
}

.faq-accordion {
  user-select: none;

  .accordion-button {
    border: none;
    border-bottom: 2px solid white;
    font-size: small;
    font-weight: normal;
  }

  .accordion-button:focus {
    box-shadow: none;
  }

  .accordion-button::after {
    background-image: url("./../assets/add-circle.svg") !important;
  }
}


.country-list {
  background: #000015 !important;
}

.react-tel-input .form-control {
  position: relative;
  font-size: small !important;

  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: transparent !important;
  border: 2px solid #009cf3 !important;
  color: white;
  border-radius: 0.25rem !important;
  line-height: 25px;
  height: 35px;
  width: 100% !important;
  outline: none;
}

.react-tel-input .form-control:focus {
  background: white !important;
  color: black !important
}

.react-tel-input .country-list .country:hover {
  background-color: #3bbaff !important;
}

.react-tel-input .country-list .country.highlight {
  background-color: #009cf3 !important;
}

.map-container {
  text-align: center; /* Centering the map within the container */
  margin: 20px 0;
}

.map-container svg {
  max-width: 100%; /* Ensuring the SVG is responsive */
  height: auto;
}
